







































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import MutationForm from '@/components/form/MutationForm.vue'

@Component({
  components: { MutationForm }
})
export default class FormCreate extends Vue {
  /** Current Environment ID */
  @Prop({ type: String, required: true }) environmentId !: string
  /** Accent Color */
  @Prop({ type: String, default: 'primary' }) accentColor !: string
  /** Dark mode */
  @Prop({ type: Boolean, default: false }) isDark !: boolean

  tab = null
}
